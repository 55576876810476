import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
// Helpers
import { renderedCheckForBooleans } from 'helpers/utils';
import { mapCampaignStatusToLabel } from 'helpers/campaign';
// Api
import { GET_CAMPAIGNS_V2 } from 'api/campaignV2/queries';
// Types
import {
  GetCampaignsV2,
  GetCampaignsV2Variables,
  GetCampaignsV2_getCampaigns_entities,
} from 'api/campaignV2/types/GetCampaignsV2';
import {
  CampaignsOrderBy,
  SortDirection,
  CampaignStatus,
  CampaignType,
} from 'api/graphql-global-types';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
// Components
import CampaignsActions from '../CampaignsActions/CampaignsActions';

const CampaignsTable = (): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [sort, setSortInfo] = useState<SortedInfo<CampaignsOrderBy>>({
    order: SortDirection.DESC,
    key: CampaignsOrderBy.createdAt,
  });

  const getCampaignsInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'type') {
        return (input['types'] = [value]);
      }
      input[key] = value;
    });

    return { input };
  };

  const { data, loading, refetch } = useQuery<
    GetCampaignsV2,
    GetCampaignsV2Variables
  >(GET_CAMPAIGNS_V2, {
    variables: {
      ...getCampaignsInput(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'Campaign ID',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Campaign name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      withSearch: true,
    },
    {
      title: 'Brand name',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 100,
      withSearch: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center' as const,
      width: 100,
      withRadioFilters: true,
      filters: [
        {
          text: 'Book me',
          value: CampaignType.BookMe,
        },
        {
          text: 'Social',
          value: CampaignType.Social,
        },
        {
          text: 'Social Share',
          value: CampaignType.SocialShare,
        },
        {
          text: 'Sponsored Appearance',
          value: CampaignType.SponsoredAppearance,
        },
        {
          text: 'Event Marketing',
          value: CampaignType.EventMarketing,
        },
      ],
    },
    {
      title: 'Due date',
      dataIndex: 'dueDate',
      align: 'center' as const,
      width: 100,
      render: function DisplayDate(
        dueData: string,
        item: GetCampaignsV2_getCampaigns_entities
      ) {
        return (
          <span>
            {item.dueDate
              ? moment(item.dueDate).local().format('MM/DD/YYYY, h:mm a')
              : 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'statuses',
      width: 150,
      withRadioFilters: true,
      filters: [
        {
          text: 'Completed',
          value: CampaignStatus.COMPLETED,
        },
        {
          text: 'Declined',
          value: CampaignStatus.DECLINED,
        },
        {
          text: 'Details filled',
          value: CampaignStatus.DETAIL_FILLED,
        },
        {
          text: 'On review',
          value: CampaignStatus.ON_REVIEW,
        },
        {
          text: 'Paid and active',
          value: CampaignStatus.PAID_AND_ACTIVE,
        },
        {
          text: 'Paid and budget fulfilled',
          value: CampaignStatus.PAID_AND_BUDGET_FULFILLED,
        },
      ],
      render: mapCampaignStatusToLabel,
    },
    {
      title: 'Physical product',
      dataIndex: 'physicalProduct',
      align: 'center' as const,
      width: 100,
      render: renderedCheckForBooleans,
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 110,
      render: function ActionsWrap(
        id: string,
        record: GetCampaignsV2_getCampaigns_entities
      ) {
        return <CampaignsActions data={record} refetchCampaigns={refetch} />;
      },
    },
  ];

  const updatedData: GetCampaignsV2_getCampaigns_entities[] =
    data?.getCampaigns.entities.map((campaign) => {
      if (
        campaign.activities?.[0].__typename === 'SponsoredAppearanceActivity'
      ) {
        if (
          campaign.activities?.[0].target?.__typename ===
          'SponsoredAppearanceGuest'
        ) {
          return {
            ...campaign,
            brandName: campaign.activities[0].target?.guestName || null,
          };
        } else if (
          campaign.activities?.[0].target?.__typename ===
          'SponsoredAppearanceProduct'
        ) {
          return {
            ...campaign,
            brandName: campaign.activities[0].target?.brandName || null,
          };
        } else {
          return { ...campaign };
        }
      } else if (
        campaign.activities?.[0].__typename === 'SocialShareActivity'
      ) {
        return {
          ...campaign,
          brandName: campaign.activities[0].brandName || null,
        };
      } else {
        return { ...campaign };
      }
    }) || [];

  return (
    <Table<GetCampaignsV2_getCampaigns_entities, CampaignsOrderBy>
      columns={columns}
      data={updatedData}
      scroll={{ x: 300 }}
      loading={loading}
      total={data?.getCampaigns.total}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );
};

export default CampaignsTable;
