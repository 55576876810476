import React from 'react';
import dayjs from 'dayjs';
// Api
import env from 'api/env';
// Types
import { GetCampaignOffers_getCampaignOffers_entities } from 'api/campaignV2/types/GetCampaignOffers';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
import { parseForInnerHTML } from 'helpers/textEditor';
import {
  getLiveAppearanceSubmission,
  getRecordedAppearanceSubmission,
} from 'helpers/deliverables';
// Styles
import styles from './SubmittedSponsored.module.scss';

const DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';

type SubmittedSponsoredProps = {
  record: GetCampaignOffers_getCampaignOffers_entities;
};

const SubmittedSponsored = ({
  record,
}: SubmittedSponsoredProps): JSX.Element => {
  const liveAppearance = getLiveAppearanceSubmission(record.submissions);
  const recordedAppearance = getRecordedAppearanceSubmission(
    record.submissions
  );

  const isRecorded =
    record?.submissions[0]?.__typename ===
    'CampaignRecordedAppearanceSubmission';

  const submission = isRecorded ? recordedAppearance : liveAppearance;

  const stream = liveAppearance?.stream;

  const streamLink = `${getEnvLink(env.REACT_APP_ENV)}/${
    stream?.store?.slug
  }/streams/${stream?.slug}`;

  return (
    <div>
      <div className={styles.item}>
        <span className={styles.label}>Talent Name:</span>
        <span className={styles.value}>
          {record.profileName ?? `${record.firstName} ${record.lastName}`}
        </span>
      </div>

      <div className={styles.item}>
        <span className={styles.label}>Appearance Date & Time:</span>
        <span className={styles.value}>
          {dayjs(stream?.scheduleDateUtc).format(DATE_TIME_FORMAT)}
        </span>
      </div>

      <div className={styles.item}>
        <span className={styles.label}>Title:</span>
        <span className={styles.value}>{stream?.name}</span>
      </div>

      <div className={styles.item}>
        <span className={styles.label}>Description:</span>
        <span
          className={styles.value}
          dangerouslySetInnerHTML={{
            __html: parseForInnerHTML(stream?.description),
          }}
        />
      </div>

      <div className={styles.item}>
        <span className={styles.label}>Stream:</span>
        <a
          className={styles.link}
          href={streamLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {streamLink}
        </a>
      </div>

      {submission?.postImageUrl && (
        <>
          <h3 className={styles.label}>Stream poster:</h3>

          {[submission.postImageUrl].map((item) => {
            return (
              <a
                key={item}
                href={item}
                className={styles.tag}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{item}</span>
              </a>
            );
          })}
        </>
      )}

      {submission?.recordUrls?.length > 0 && (
        <div className={styles.clips}>
          <h3 className={styles.label}>Stream recording:</h3>

          {submission?.recordUrls.map((item) => {
            return (
              <a
                key={item}
                href={item}
                className={styles.tag}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{item}</span>
              </a>
            );
          })}
        </div>
      )}

      {submission?.clipUrls?.length > 0 && (
        <>
          <p className={styles.sectionTitle}>MEDIA LABS</p>

          <h3 className={styles.label}>Promo Media Recoding:</h3>

          {submission?.clipUrls.map((item) => {
            return (
              <a
                key={item}
                href={item}
                className={styles.tag}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{item}</span>
              </a>
            );
          })}
        </>
      )}

      {submission?.mediaLinks?.length > 0 && (
        <h3 className={styles.label}>Promo Link:</h3>
      )}

      {submission?.mediaLinks?.map((item, index) => {
        return (
          <div className={styles.item} key={index}>
            <a
              className={styles.link}
              href={item}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default SubmittedSponsored;
