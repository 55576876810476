import React from 'react';
// Types
import { GetCampaignOffers_getCampaignOffers_entities } from 'api/campaignV2/types/GetCampaignOffers';
import { CampaignType } from 'api/graphql-global-types';
// Components
import SocialDeliverables from './components/SocialDeliverables/SocialDeliverables';
import SponsoredDeliverables from './components/SponsoredDeliverables/SponsoredDeliverables';
import SocialShareDeliverables from './components/SocialShareDeliverables/SocialShareDeliverables';

type CampaignDeliverablesProps = {
  record: GetCampaignOffers_getCampaignOffers_entities | null;
  onClose: () => void;
  onFinish: () => void;
};
const CampaignDeliverables = ({
  record,
  onClose,
  onFinish,
}: CampaignDeliverablesProps): JSX.Element => {
  const campaignType = record?.campaign.type;
  const isSocial = campaignType === CampaignType.Social;
  const isSocialShare = campaignType === CampaignType.SocialShare;
  const isAppearance = campaignType === CampaignType.SponsoredAppearance;

  return (
    <>
      {isSocial && (
        <SocialDeliverables
          record={record}
          onClose={onClose}
          onFinish={onFinish}
        />
      )}
      {isSocialShare && (
        <SocialShareDeliverables
          record={record}
          onClose={onClose}
          onFinish={onFinish}
        />
      )}
      {isAppearance && (
        <SponsoredDeliverables
          record={record}
          onClose={onClose}
          onFinish={onFinish}
        />
      )}
    </>
  );
};

export default CampaignDeliverables;
