//Types
import {
  GetCampaignOffers_getCampaignOffers_entities,
  GetCampaignOffers_getCampaignOffers_entities_submissions,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignFBShareSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGLiveSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGPostSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGReelSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGShareSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGStorySubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignLiveAppearanceSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignRecordedAppearanceSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignTTShareSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignTTVideoSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignTWShareSubmission,
  GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignYTShareSubmission,
} from 'api/campaignV2/types/GetCampaignOffers';
import {
  CampaignOfferSubmissionInput,
  CampaignTaskType,
} from 'api/graphql-global-types';
import { UploadImage } from 'ui/UploadMultipleImages/UploadMultipleImages';
import { PresignedUrlResultItem } from './single-uploader';

export type SocialStateType = {
  iGPosts: string[];
  iGReels: string[];
  tikToks: string[];
  storyImages: UploadImage[];
  liveImages: UploadImage[];
};

export enum SocialStateEnum {
  iGPosts = 'iGPosts',
  iGReels = 'iGReels',
  tikToks = 'tikToks',
  storyImages = 'storyImages',
  liveImages = 'liveImages',
}

export const EMPTY_SOCIAL_STATE: SocialStateType = {
  iGPosts: [],
  iGReels: [],
  tikToks: [],
  storyImages: [],
  liveImages: [],
};

export type SocialSubmissions =
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGReelSubmission
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGPostSubmission
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignTTVideoSubmission;

export const getInitialSocialState = (
  record: GetCampaignOffers_getCampaignOffers_entities | null
): SocialStateType => {
  const storySubmissions: UploadImage[] = [];
  const liveSubmissions: UploadImage[] = [];
  (
    record?.submissions as GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGStorySubmission[]
  ).forEach((item) => {
    if (item.type === CampaignTaskType.IG_STORY) {
      storySubmissions.push({
        data_url: item.url,
        data_key: item.imageKey,
        file: undefined,
      });
    }
  });

  (
    record?.submissions as GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGLiveSubmission[]
  ).forEach((item) => {
    if (item.type === CampaignTaskType.IG_LIVE) {
      liveSubmissions.push({
        data_url: item.url,
        data_key: item.imageKey,
        file: undefined,
      });
    }
  });

  const postSubmissions: string[] = [];
  const reelSubmissions: string[] = [];
  const tikTokSubmissions: string[] = [];

  (record?.submissions as SocialSubmissions[]).forEach((item) => {
    if (item.type === CampaignTaskType.IG_POST) {
      postSubmissions.push(item.link);
    }
    if (item.type === CampaignTaskType.IG_REEL) {
      reelSubmissions.push(item.link);
    }
    if (item.type === CampaignTaskType.TT_VIDEO) {
      tikTokSubmissions.push(item.link);
    }
  });

  const result: SocialStateType = {
    storyImages: storySubmissions,
    liveImages: liveSubmissions,
    iGPosts: postSubmissions.length ? postSubmissions : [''],
    iGReels: reelSubmissions.length ? reelSubmissions : [''],
    tikToks: tikTokSubmissions.length ? tikTokSubmissions : [''],
  };

  return result;
};

export const arrangeSubmissions = (
  state: SocialStateType,
  imageKeys: PresignedUrlResultItem[]
): CampaignOfferSubmissionInput[] => {
  const result: CampaignOfferSubmissionInput[] = [];

  state.liveImages.forEach((image) => {
    result.push({
      imageKey: image.file
        ? imageKeys.shift()?.key || ''
        : image.data_key || '',
      type: CampaignTaskType.IG_LIVE,
    });
  });
  state.storyImages.forEach((image) => {
    result.push({
      imageKey: image.file
        ? imageKeys.shift()?.key || ''
        : image.data_key || '',
      type: CampaignTaskType.IG_STORY,
    });
  });

  state.iGPosts.forEach((item) => {
    if (item !== '') {
      result.push({
        link: item,
        type: CampaignTaskType.IG_POST,
      });
    }
  });
  state.iGReels.forEach((item) => {
    if (item !== '') {
      result.push({
        link: item,
        type: CampaignTaskType.IG_REEL,
      });
    }
  });
  state.tikToks.forEach((item) => {
    if (item !== '') {
      result.push({
        link: item,
        type: CampaignTaskType.TT_VIDEO,
      });
    }
  });

  return result;
};

export enum SocialShareStateEnum {
  iGShare = 'iGShare',
  tTShare = 'tTShare',
  // tWShare = 'tWShare',
  // fBShare = 'fBShare',
  // yTShare = 'yTShare',
}

export type SocialShareData = {
  link: string;
  images: UploadImage[];
};

export const EMPTY_SOCIAL_SHARE_STATE: SocialShareData = {
  link: '',
  images: [],
};

export type SocialShareSubmissions =
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignIGShareSubmission
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignTTShareSubmission
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignFBShareSubmission
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignTWShareSubmission
  | GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignYTShareSubmission;

export const getInitialSocialShareState = (
  record: GetCampaignOffers_getCampaignOffers_entities | null
): SocialShareData => {
  const submission = (record?.submissions as SocialShareSubmissions[])[0];

  const result: SocialShareData = {
    link: submission?.shareLink ?? '',
    images:
      submission?.shareImage && submission?.shareUrl
        ? [
            {
              data_url: submission.shareUrl,
              data_key: submission.shareImage,
              file: undefined,
            },
          ]
        : [],
  };

  return result;
};

export const getSocialShareSubmission = (
  state: SocialShareData,
  imageKeys: PresignedUrlResultItem[],
  type: CampaignTaskType
): CampaignOfferSubmissionInput[] => {
  const result: CampaignOfferSubmissionInput = {
    ...(state.images?.length && {
      imageKey: state.images?.[0].file
        ? imageKeys[0].key || ''
        : state.images?.[0].data_key || '',
    }),
    ...(state.link && { link: state.link }),
    type,
  };

  return [result];
};

export const getLiveAppearanceSubmission = (
  submissions: GetCampaignOffers_getCampaignOffers_entities_submissions[]
): GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignLiveAppearanceSubmission => {
  return submissions[0] as GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignLiveAppearanceSubmission;
};

export const getRecordedAppearanceSubmission = (
  submissions: GetCampaignOffers_getCampaignOffers_entities_submissions[]
): GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignRecordedAppearanceSubmission => {
  return submissions[0] as GetCampaignOffers_getCampaignOffers_entities_submissions_CampaignRecordedAppearanceSubmission;
};
