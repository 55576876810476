import React from 'react';
// Types
import { SearchCampaignStores_searchCampaignStores_entities } from 'api/campaignV2/types/SearchCampaignStores';
import { SearchSocialShareCampaignStores_searchSocialShareCampaignStores_entities } from 'api/campaignV2/types/SearchSocialShareCampaignStores';
// Helpers
import { formatAudienceSize } from 'helpers/campaignEditModal';
// Component
import TalentItem from '../TalentItem/TalentItem';
// Styles
import styles from './TalentList.module.scss';

type TalentListProps = {
  talents:
    | SearchCampaignStores_searchCampaignStores_entities[]
    | SearchSocialShareCampaignStores_searchSocialShareCampaignStores_entities[];
  hasMore: boolean;
  totalTalents: number;
  totalFollowers: number;
};

const TalentList = ({
  talents,
  hasMore,
  totalTalents,
  totalFollowers,
}: TalentListProps): JSX.Element | null => {
  return (
    <div className={styles.root}>
      {hasMore && (
        <div className={styles.following}>
          {totalTalents} matches for your criteria with{' '}
          {formatAudienceSize((totalFollowers ?? 0).toString(), 1)} combined
          following
        </div>
      )}

      <div className={styles.itemWrapper}>
        {talents?.map((talent) => (
          <TalentItem key={talent.id} talent={talent} />
        ))}
      </div>
    </div>
  );
};
export default TalentList;
