import { gql } from '@apollo/client';

export const GET_CAMPAIGNS_V2 = gql`
  query GetCampaignsV2($input: GetCampaignsInput!) {
    getCampaigns(input: $input) {
      entities {
        usageRights
        usageRightsText
        agency {
          id
          email
          phoneNumber
          firstName
          lastName
          agencyDetails {
            companyName
          }
        }
        activities {
          ... on SocialActivity {
            id
            avoid
            brandName
            encouraged
            files {
              id
              key
              url
            }
            hashtags
            instructions
            mediaType
            physicalProduct
            physicalProductType
            profileMentions
            promoCodes
            promoUrls
            quantityInstructions
            script
            submissionDue
            suggestedCaption
            tasks {
              id
              type
            }
            website
            postDueFrom
            postDueTo
          }
          ... on SponsoredAppearanceActivity {
            id
            type
            videoType
            withAmplify
            target {
              ... on SponsoredAppearanceGuest {
                id
                type
                instagram
                instructions
                linkedin
                otherSocial
                guestName: name
                guestBio: bio
                company
                email
              }
              ... on SponsoredAppearanceProduct {
                id
                type
                instagram
                instructions
                linkedin
                otherSocial
                brandName: name
                productBio: bio
                website
                physicalProduct
                physicalProductType
                files {
                  id
                  key
                  url
                }
              }
            }
          }
          ... on InPersonAppearanceActivity {
            id
            address
            state
            type
            venueName
            zip
            city
            country
            coveredExpenses
            description
            duration
            files {
              id
              key
              url
            }
            instructions
            possibleDates
          }
          ... on VirtualAppearanceActivity {
            id
            description
            duration
            files {
              id
              key
              url
            }
            instructions
            location
            possibleDates
          }
          ... on CustomActivity {
            id
            instructions
            files {
              id
              key
              url
            }
          }
          ... on EventMarketingActivity {
            id
            description
            estimatedViews
            participantCount
            shortFormVideoCount
            socialMediaLinks
            sport
            startDate
            type
          }
          ... on SocialShareActivity {
            id
            brandName
            socialShareDescription: description
            website
            instructions
            shareUrl
            type
            tasks {
              id
              type
            }
          }
        }
        brandName
        budget
        budgetLeft
        createdAt
        dueDate
        flatFeePerStore
        id
        name
        search {
          id
          athleteMaxAge
          athleteMinAge
          budget
          genders
          locations {
            city
            country
            state
          }
          maxFollowers
          minFollowers
          roles
          smPlatforms
          sports
        }
        status
        totalAthletes
        totalCost
        totalFollowers
        totalOriginalAthletes
        type
        updatedAt
      }
      limit
      offset
      total
    }
  }
`;

export const GET_CAMPAIGN_OFFERS = gql`
  query GetCampaignOffers($input: GetCampaignOffersInput!) {
    getCampaignOffers(input: $input) {
      limit
      offset
      total
      entities {
        id
        userId
        firstName
        lastName
        profileName
        socialFollowing
        user {
          socialMedia {
            engagementRate
            error
            followingCnt
            id
            profileName
            sourceKey
            type
            url
            userId
            username
          }
        }
        submittedAt
        statusV2
        submissions {
          id
          type
          ... on CampaignLiveAppearanceSubmission {
            streamCohostToken
            clipUrls
            clipThumbUrls
            mediaLinks
            postImageUrl
            event {
              schedule {
                timezone
              }
            }
            recordUrls
            stream {
              id
              name
              description
              slug
              imageFileKey
              imageURL
              mainImageUrl
              scheduleDateUtc
              scheduleDateTz
              streamStatus
              hashtags {
                id
                name
              }
              mentions {
                id
                mentionType
                store {
                  id
                }
                targetType
                unregisteredContact {
                  id
                }
              }
              store {
                slug
              }
            }
          }
          ... on CampaignRecordedAppearanceSubmission {
            streamCohostToken
            clipUrls
            clipThumbUrls
            mediaLinks
            postImageUrl
            event {
              schedule {
                timezone
              }
            }
            recordUrls
            stream {
              id
              name
              description
              slug
              imageFileKey
              imageURL
              mainImageUrl
              scheduleDateUtc
              scheduleDateTz
              streamStatus
              hashtags {
                id
                name
              }
              mentions {
                id
                mentionType
                store {
                  id
                }
                targetType
                unregisteredContact {
                  id
                }
              }
              store {
                slug
              }
            }
            draftStream {
              id
              description
              slug
              imageFileKey
              imageURL
              mainImageUrl
              name
              scheduleDateUtc
              streamStatus
              scheduleDateTz
              hashtags {
                id
                name
              }
              mentions {
                id
                mentionType
                store {
                  id
                }
                targetType
                unregisteredContact {
                  id
                }
              }
              store {
                slug
              }
            }
          }

          ... on CampaignIGLiveSubmission {
            imageKey
            url
          }
          ... on CampaignIGPostSubmission {
            link
          }
          ... on CampaignIGReelSubmission {
            link
          }
          ... on CampaignIGStorySubmission {
            imageKey
            url
          }
          ... on CampaignTTVideoSubmission {
            link
          }
          ... on CampaignIGShareSubmission {
            shareLink: link
            shareImage: imageKey
            shareUrl: url
            type
          }
          ... on CampaignTTShareSubmission {
            shareLink: link
            shareImage: imageKey
            shareUrl: url
            type
          }
          ... on CampaignFBShareSubmission {
            shareLink: link
            shareImage: imageKey
            shareUrl: url
            type
          }
          ... on CampaignTWShareSubmission {
            shareLink: link
            shareImage: imageKey
            shareUrl: url
            type
          }
          ... on CampaignYTShareSubmission {
            shareLink: link
            shareImage: imageKey
            shareUrl: url
            type
          }
        }
        campaign {
          search {
            smPlatforms
          }
          agency {
            id
          }
          activities {
            ... on SocialActivity {
              id
              type
              tasks {
                type
                id
              }
              files {
                id
                key
                url
              }
            }
            ... on SocialShareActivity {
              id
              brandName
              website
              description
              instructions
              shareUrl
              tasks {
                id
                type
              }
              type
            }
          }
          type
        }
      }
    }
  }
`;

export const SEARCH_CAMPAIGN_STORES = gql`
  query SearchCampaignStores($input: SearchCampaignStoresInput!) {
    searchCampaignStores(input: $input) {
      entities {
        id
        firstName
        lastName
        email
        slug
        socialMedia {
          engagementRate
          error
          followingCnt
          id
          profileName
          sourceKey
          type
          url
          userId
          username
        }
        sports {
          id
          name
        }
        storeDetails {
          storeId
          storeName
          avatarURL
          country
          gender
          socialFollowing
          birthDate
        }
      }
      summary {
        totalCost
        cost
        followers
      }
      searchCriteria {
        id
        athleteMaxAge
        athleteMinAge
        budget
        flatFeePerStore
        genders
        maxFollowers
        minFollowers
        smPlatforms
        sports
        locations {
          country
          state
        }
      }
      limit
      offset
      total
    }
  }
`;

export const SEARCH_SPONSORED_APPEARANCE_CAMPAIGN_STORES = gql`
  query SearchSponsoredAppearanceCampaignStores(
    $input: SearchSponsoredAppearanceInput!
  ) {
    searchSponsoredAppearanceCampaignStores(input: $input) {
      entities {
        id
        firstName
        lastName
        email
        slug
        sports {
          id
          name
        }
        socialMedia {
          engagementRate
          error
          followingCnt
          id
          profileName
          sourceKey
          type
          url
          userId
          username
        }
        storeDetails {
          storeId
          storeName
          avatarURL
          socialFollowing
          country
          gender
          socialFollowing
          birthDate
        }
      }
      searchCriteria {
        appearanceTargetType
        appearanceVideoType
        withAmplify
        athleteMinAge
        athleteMaxAge
        budget
        flatFeePerStore
        genders
        id
        locations {
          country
          state
        }
        maxFollowers
        minFollowers
        roles
        smPlatforms
        sports
        type
      }
      summary {
        cost
        followers
        totalCost
      }
      limit
      offset
      total
    }
  }
`;

export const GET_AGENCIES = gql`
  query GetAgencies($input: GetAgenciesInput!) {
    getAgencies(input: $input) {
      entities {
        id
        email
        firstName
        lastName
        agencyDetails {
          companyName
        }
      }
      limit
      offset
      total
    }
  }
`;

export const SEARCH_SOCIAL_SHARE_CAMPAIGN_STORES = gql`
  query SearchSocialShareCampaignStores(
    $input: SearchSocialShareCampaignInput!
  ) {
    searchSocialShareCampaignStores(input: $input) {
      entities {
        id
        firstName
        lastName
        email
        slug
        sports {
          id
          name
        }
        socialMedia {
          engagementRate
          error
          followingCnt
          id
          profileName
          sourceKey
          type
          url
          userId
          username
        }
        storeDetails {
          storeId
          storeName
          avatarURL
          socialFollowing
          country
          gender
          socialFollowing
          birthDate
        }
      }
      summary {
        totalCost
        cost
        followers
      }
      searchCriteria {
        id
        athleteMaxAge
        athleteMinAge
        budget
        flatFeePerStore
        genders
        maxFollowers
        minFollowers
        smPlatforms
        sports
        roles
        locations {
          country
          state
        }
      }
      limit
      offset
      total
    }
  }
`;
