import { gql } from '@apollo/client';

export const DOWNLOAD_CAMPAIGN_OFFERS = gql`
  mutation DownloadCampaignOffersFull(
    $input: DownloadCampaignOffersFullInput!
  ) {
    downloadCampaignOffersFull(input: $input) {
      csvUrl
    }
  }
`;

export const APPROVE_CAMPAIGN = gql`
  mutation ApproveCampaign($input: ApproveCampaignInput!) {
    approveCampaign(input: $input) {
      id
      status
    }
  }
`;

export const DECLINE_CAMPAIGN = gql`
  mutation DeclineCampaign($input: DeclineCampaignInput!) {
    declineCampaign(input: $input) {
      id
      status
    }
  }
`;

export const CREATE_CAMPAIGN_EXAMPLE_FILES = gql`
  mutation CreateCampaignExampleFiles(
    $input: CreateCampaignExampleFilesInput!
  ) {
    createCampaignExampleFiles(input: $input) {
      fields
      key
      url
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
    }
  }
`;

export const UPDATE_BOOK_ME_CAMPAIGN = gql`
  mutation UpdateBookMeCampaign($input: UpdateBookMeCampaignInput!) {
    updateBookMeCampaign(input: $input) {
      id
    }
  }
`;

export const UPDATE_SPONSORED_APPEARANCE_CAMPAIGN = gql`
  mutation UpdateSponsoredAppearanceCampaign(
    $input: UpdateSponsoredAppearanceInput!
  ) {
    updateSponsoredAppearanceCampaign(input: $input) {
      id
    }
  }
`;

export const UPDATE_EVENT_MARKETING_CAMPAIGN = gql`
  mutation UpdateEventMarketingCampaign(
    $input: UpdateEventMarketingCampaignInput!
  ) {
    updateEventMarketingCampaign(input: $input) {
      id
      name
      activities {
        ... on EventMarketingActivity {
          id
          description
          estimatedViews
          participantCount
          shortFormVideoCount
          socialMediaLinks
          sport
          startDate
          type
        }
      }
    }
  }
`;

export const ADD_OFFLINE_PAYMENT = gql`
  mutation AddOfflineCampaignPayment($input: AddOfflineCampaignPaymentInput!) {
    addOfflineCampaignPayment(input: $input) {
      id
    }
  }
`;

export const COMPLETE_CAMPAIGN = gql`
  mutation CompleteCampaign($input: CompleteCampaignInput!) {
    completeCampaign(input: $input) {
      id
      status
      type
    }
  }
`;

export const COMPLETED_CAMPAIGN_OFFER = gql`
  mutation CompleteCampaignOffer($input: CompleteCampaignOfferInput!) {
    completeCampaignOffer(input: $input) {
      id
      statusV2
    }
  }
`;

export const ADD_ATHLETE_TO_CAMPAIGN = gql`
  mutation AddAthleteToCampaign($input: AddOfferToCampaignInput!) {
    addOfferToCampaign(input: $input) {
      id
    }
  }
`;

export const CREATE_CAMPAIGN_SUBMISSION_FILES = gql`
  mutation CreateCampaignSubmissionFiles(
    $input: CreateCampaignSubmissionFilesInput!
  ) {
    createCampaignSubmissionFiles(input: $input) {
      fields
      key
      url
    }
  }
`;

export const SUBMIT_CAMPAIGN_OFFER_RESULTS = gql`
  mutation SubmitCampaignOfferResults(
    $input: SubmitCampaignOfferResultsInput!
  ) {
    submitCampaignOfferResults(input: $input) {
      id
      statusV2
      submissions {
        id
        type
        ... on CampaignIGLiveSubmission {
          imageKey
          url
        }
        ... on CampaignIGPostSubmission {
          link
        }
        ... on CampaignIGReelSubmission {
          link
        }
        ... on CampaignIGStorySubmission {
          imageKey
          url
        }
        ... on CampaignTTVideoSubmission {
          link
        }
      }
    }
  }
`;

export const CREATE_SPONSORED_APPEARANCE_IMAGE = gql`
  mutation CreateSponsoredAppearanceImage($input: CreateStreamImageInput!) {
    createStreamImage(input: $input) {
      fields
      key
      url
    }
  }
`;

export const INIT_STREAM_MULTIPART_UPLOAD = gql`
  mutation InitStreamMultipartUpload($input: InitStreamMultipartUploadInput!) {
    initStreamMultipartUpload(input: $input) {
      fileId
      fileKey
      signedUrls {
        partNumber
        signedUrl
      }
    }
  }
`;

export const COMPLETE_STREAM_MULTIPART_UPLOAD = gql`
  mutation CompleteStreamMultipartUpload(
    $input: CompleteMultipartUploadInput!
  ) {
    completeStreamMultipartUpload(input: $input)
  }
`;

export const SUBMIT_PAST_APPEARANCE_STREAM = gql`
  mutation SubmitPastAppearanceStream(
    $input: CreatePastAppearanceStreamInput!
  ) {
    submitPastAppearanceStream(input: $input) {
      id
    }
  }
`;

export const UPDATE_CAMPAIGN_OWNERSHIP = gql`
  mutation UpdateCampaignOwnership($input: UpdateCampaignOwnershipInput!) {
    updateCampaignOwnership(input: $input) {
      id
    }
  }
`;

export const UPDATE_SOCIAL_SHARE_CAMPAIGN = gql`
  mutation UpdateSocialShareCampaign($input: UpdateSocialShareCampaignInput!) {
    updateSocialShareCampaign(input: $input) {
      id
      name
      status
      activities {
        ... on SocialShareActivity {
          id
          brandName
          instructions
          shareUrl
          tasks {
            id
            type
          }
          type
        }
      }
      budget
      budgetLeft
      createdAt
      dueDate
      flatFeePerStore
    }
  }
`;
