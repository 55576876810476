import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Form,
  Input,
  Typography,
  Select,
  DatePicker,
  Checkbox,
  Switch,
  Button,
} from 'antd';
// Api
import {
  CREATE_CAMPAIGN_EXAMPLE_FILES,
  UPDATE_SPONSORED_APPEARANCE_CAMPAIGN,
} from 'api/campaignV2/mutations';
import { SEARCH_SPONSORED_APPEARANCE_CAMPAIGN_STORES } from 'api/campaignV2/queries';
// Hooks
import { useGetSports } from 'hooks';
// Helpers
import {
  FOLLOWING_OPTIONS_MAX,
  FOLLOWING_OPTIONS_MIN,
  GENDER_OPTIONS,
  PRODUCT_TYPES,
  uploadImages,
} from 'helpers/campaignEditModal';
// Constants
import { dateTimeFormat } from 'constants/global';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity_target,
  GetCampaignsV2_getCampaigns_entities_search,
} from 'api/campaignV2/types/GetCampaignsV2';
import {
  CampaignActivityTargetType,
  CampaignActivityType,
  SearchSponsoredAppearanceCriteriaInput,
  SponsoredAppearanceActivityDetailsInput,
  SponsoredAppearanceActivityInput,
  SponsoredAppearanceVideoType,
  StoreGender,
  UpdateSponsoredAppearanceInput,
  UserRole,
} from 'api/graphql-global-types';
import {
  CreateCampaignExampleFiles,
  CreateCampaignExampleFilesVariables,
} from 'api/campaignV2/types/CreateCampaignExampleFiles';
import {
  UpdateSponsoredAppearanceCampaign,
  UpdateSponsoredAppearanceCampaignVariables,
} from 'api/campaignV2/types/UpdateSponsoredAppearanceCampaign';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  SearchSponsoredAppearanceCampaignStores,
  SearchSponsoredAppearanceCampaignStoresVariables,
} from 'api/campaignV2/types/SearchSponsoredAppearanceCampaignStores';
// Ui
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import TalentList from '../../../CampaignDetails/CampaignDetails/components/TalentList/TalentList';
// Style
import styles from './SponsoredAppearanceCampaignEditModal.module.scss';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

type FormValues = {
  // criteria/filters
  userRole: UserRole[];
  budget: number;
  flatFeePerStore: number;
  genders?: StoreGender[] | null;
  maxAge?: number | null;
  minAge?: number | null;
  followingFrom: number | null;
  followingTo: number | null;
  sports?: string[] | null;
  // sponsored appearance common
  appearanceVideoType?: SponsoredAppearanceVideoType | null;
  name: string;
  dueDate: any;
  // target (common)
  appearanceTargetType?: CampaignActivityTargetType | null;
  type?: CampaignActivityType | null;
  instagram?: string | null;
  instructions: string;
  linkedin?: string | null;
  otherSocial?: string | null;
  // target (guest only)
  guestName?: string | null;
  guestBio?: string | null;
  company?: string | null;
  email: string;
  // target (product only)
  brandName?: string | null;
  productBio?: string | null;
  website?: string | null;
  physicalProductType?: string | null;
  usageRightsText: string | null;
};

type ImageFiles = {
  url: string | null;
  key: string;
  file: File | undefined;
};

type SponsoredAppearanceCampaignEditModalProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onFinish: () => void;
};

const SponsoredAppearanceCampaignEditModal = ({
  record,
  onFinish,
}: SponsoredAppearanceCampaignEditModalProps): JSX.Element | null => {
  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const sponsoredAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'SponsoredAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity;

  const [isLive, setIsLive] = useState<boolean>(
    sponsoredAppearanceActivity.videoType === SponsoredAppearanceVideoType.Live
  );

  const [usageRights, setUsageRights] = useState<boolean>(
    record?.usageRights || false
  );

  const [isAmplify, setIsAmplify] = useState<boolean>(
    sponsoredAppearanceActivity.withAmplify || false
  );

  const target =
    sponsoredAppearanceActivity?.target as GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity_target;

  const [isGuest, setIsGuest] = useState<boolean>('guestBio' in target);

  const initialImages =
    'files' in target
      ? target?.files.map((item) => {
          return { url: item.url, key: item.key, file: undefined };
        })
      : [];

  const [imageFiles, setImageFiles] = useState<ImageFiles[]>(initialImages);

  const initialProduct =
    'physicalProduct' in target ? target.physicalProduct : false;

  const [isPhysicalProduct, setIsPhysicalProduct] =
    useState<boolean>(initialProduct);

  const { data: sportsData } = useGetSports({
    variables: {
      storeRoles: [UserRole.Athlete, UserRole.ContentCreator],
    },
  });

  const [searchStores, { data: storeData, loading: loadingStoreData }] =
    useLazyQuery<
      SearchSponsoredAppearanceCampaignStores,
      SearchSponsoredAppearanceCampaignStoresVariables
    >(SEARCH_SPONSORED_APPEARANCE_CAMPAIGN_STORES);

  const [updateCampaign, { loading: loadingUpdateCampaign }] = useMutation<
    UpdateSponsoredAppearanceCampaign,
    UpdateSponsoredAppearanceCampaignVariables
  >(UPDATE_SPONSORED_APPEARANCE_CAMPAIGN);

  const [
    createCampaignExampleFiles,
    { loading: loadingCreateCampaignExampleFiles },
  ] = useMutation<
    CreateCampaignExampleFiles,
    CreateCampaignExampleFilesVariables
  >(CREATE_CAMPAIGN_EXAMPLE_FILES);

  const filters = record?.search as GetCampaignsV2_getCampaigns_entities_search;

  const allSports = sportsData?.sports || [];

  const productImages: UploadImage[] = [];
  imageFiles?.forEach((image) => {
    productImages.push({
      data_key: image.key,
      data_url: image.url || undefined,
    });
  });

  const initialFormValues: FormValues = {
    userRole: filters?.roles || [],
    budget: filters?.budget || 0,
    flatFeePerStore: record?.flatFeePerStore || 0,
    genders: filters?.genders || [],
    maxAge: filters?.athleteMaxAge || null,
    minAge: filters?.athleteMinAge || null,
    followingFrom:
      FOLLOWING_OPTIONS_MIN.find((f) => f.value === filters.minFollowers)
        ?.value || null,
    followingTo:
      FOLLOWING_OPTIONS_MAX.find((f) => f.value === filters.maxFollowers)
        ?.value || null,
    sports: filters?.sports || [],
    appearanceVideoType: sponsoredAppearanceActivity.videoType,
    name: record?.name || '',
    dueDate: record?.dueDate ? moment(record?.dueDate) : null,
    type: sponsoredAppearanceActivity?.type,
    appearanceTargetType: target.type,
    instagram: target.instagram || '',
    instructions: target.instructions || '',
    linkedin: target.linkedin || '',
    otherSocial: target.otherSocial || '',
    guestName: 'guestName' in target ? target.guestName : '',
    guestBio: 'guestBio' in target ? target.guestBio : '',
    company: 'company' in target ? target.company : '',
    email: 'email' in target ? target.email : '',

    brandName: 'brandName' in target ? target.brandName : '',
    productBio: 'productBio' in target ? target.productBio : '',
    website: 'website' in target ? target.website : '',
    physicalProductType:
      'physicalProductType' in target ? target.physicalProductType : '',
    usageRightsText: record?.usageRightsText || null,
  };

  const handleSubmit = async () => {
    const {
      userRole,
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      followingFrom,
      followingTo,
      sports,
      name,
      dueDate,
      guestName,
      company,
      email,
      guestBio,
      brandName,
      website,
      productBio,
      instagram,
      linkedin,
      otherSocial,
      instructions,
      physicalProductType,
      usageRightsText,
    } = form.getFieldsValue();

    const imageFilesToUpload: File[] = [];
    const uploadUrls: string[] = [];

    imageFiles.forEach((image) => {
      if (image.file) {
        imageFilesToUpload.push(image.file);
      } else if (image.key) {
        uploadUrls.push(image.key);
      }
    });

    try {
      if (imageFilesToUpload.length && isGuest) {
        const { data: campaignExampleFiles } = await createCampaignExampleFiles(
          {
            variables: {
              input: {
                files: imageFilesToUpload.map((file) => ({
                  name: file.name,
                })),
              },
            },
          }
        );
        if (campaignExampleFiles?.createCampaignExampleFiles?.length) {
          setIsUploading(true);
          const urls = await uploadImages(
            campaignExampleFiles.createCampaignExampleFiles,
            imageFilesToUpload
          );
          setIsUploading(false);
          uploadUrls.push(...urls);
        }
      }

      const sponsoredAppearance: SponsoredAppearanceActivityDetailsInput = {
        target: isGuest
          ? CampaignActivityTargetType.SponsoredGuest
          : CampaignActivityTargetType.SponsoredProduct,
        videoType: isLive
          ? SponsoredAppearanceVideoType.Live
          : SponsoredAppearanceVideoType.Recorded,
        ...(isLive && { withAmplify: isAmplify }),
        ...(isGuest && {
          guest: {
            bio: guestBio || '',
            company,
            email,
            instagram,
            instructions,
            linkedin,
            name: guestName || '',
            otherSocial,
          },
        }),
        ...(!isGuest && {
          product: {
            bio: productBio || '',
            instagram,
            instructions,
            linkedin,
            name: brandName || '',
            otherSocial,
            physicalProduct: isPhysicalProduct,
            physicalProductType: isPhysicalProduct ? physicalProductType : null,
            website,
            // place urls for files here
            files: uploadUrls.map((url) => ({ key: url })),
          },
        }),
      };

      const activities: SponsoredAppearanceActivityInput[] = [
        {
          sponsoredAppearance,
          type: CampaignActivityType.SponsoredAppearance,
        },
      ];

      const criteria: SearchSponsoredAppearanceCriteriaInput = {
        roles: userRole?.length
          ? userRole
          : [UserRole.Athlete, UserRole.ContentCreator],
        budget: Number(budget),
        flatFeePerStore: Number(flatFeePerStore),
        genders: genders || null,
        ...(maxAge && {
          maxAge: Number(maxAge),
        }),
        ...(minAge && {
          minAge: Number(minAge),
        }),
        ...(followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
          },
        }),
        ...(followingTo && {
          socialFollowersRange: {
            to: Number(followingTo),
          },
        }),
        ...(followingTo &&
          followingFrom && {
            socialFollowersRange: {
              from: Number(followingFrom),
              to: Number(followingTo),
            },
          }),
        sports,
      };

      const updateCampaignInputVariables: UpdateSponsoredAppearanceInput = {
        activities,
        criteria,
        dueDate,
        id: record?.id || '',
        usageRights,
        usageRightsText,
        name,
      };

      const { data } = await updateCampaign({
        variables: {
          input: updateCampaignInputVariables,
        },
      });
      if (data?.updateSponsoredAppearanceCampaign) {
        successNotification('The campaign was successfully updated');
        onFinish();
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign update error:', { error });
    }
  };

  const handleChangeType = () => {
    setIsGuest(!isGuest);
  };

  const handleChangeVideoType = () => {
    setIsLive(!isLive);
    setIsAmplify(false);
  };

  const handleChangeAmplify = () => {
    setIsAmplify(!isAmplify);
  };

  const handleSetImages = (incomingData: UploadImage[]) => {
    const newImages: ImageFiles[] = [];

    incomingData.forEach((image) => {
      newImages.push({
        key: image.data_key || '',
        url: image.data_url || null,
        file: image.file || undefined,
      });
    });

    setImageFiles(newImages);
  };

  const handleIsPhysicalProductChange = () => {
    if (isPhysicalProduct) {
      form.setFieldsValue({
        physicalProductType: null,
      });
    }

    setIsPhysicalProduct(!isPhysicalProduct);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // allow days 2 weeks from today
    return current && current < moment().add('2', 'weeks');
  };

  const handleUsageRightsChange = () => {
    if (usageRights) {
      form.setFieldsValue({
        usageRights: null,
      });
    }

    setUsageRights(!usageRights);
  };

  const handleFilterSearch = async (
    criteria: SearchSponsoredAppearanceCriteriaInput
  ) => {
    try {
      searchStores({
        variables: {
          input: {
            limit: 4,
            offset: 0,
            criteria,
          },
        },
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign filter error:', { error });
    }
  };

  const handleFilterClick = async () => {
    const {
      userRole,
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      followingFrom,
      followingTo,
      sports,
    } = form.getFieldsValue();

    const criteria: SearchSponsoredAppearanceCriteriaInput = {
      roles: userRole?.length
        ? userRole
        : [UserRole.Athlete, UserRole.ContentCreator],
      budget: Number(budget),
      flatFeePerStore: Number(flatFeePerStore),
      genders: genders || null,
      ...(maxAge && {
        maxAge: Number(maxAge),
      }),
      ...(minAge && {
        minAge: Number(minAge),
      }),
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    handleFilterSearch(criteria);
  };

  const talentsData =
    storeData?.searchSponsoredAppearanceCampaignStores?.entities;

  const hasMore =
    (talentsData?.length ?? 0) <
    (storeData?.searchSponsoredAppearanceCampaignStores.total ?? 0);

  const showTalentList =
    !!talentsData?.length && talentsData?.length > 0 && !loadingStoreData;

  const totalFollowers = Number(
    storeData?.searchSponsoredAppearanceCampaignStores.summary.followers
  );
  const totalTalents = Number(
    storeData?.searchSponsoredAppearanceCampaignStores.total
  );

  useEffect(() => {
    // Used to call the filters API and display the initial talents
    const {
      userRole,
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      followingFrom,
      followingTo,
      sports,
    } = initialFormValues;

    const criteria: SearchSponsoredAppearanceCriteriaInput = {
      roles: userRole,
      budget,
      flatFeePerStore,
      genders,
      locations: filters?.locations.map((location) => {
        return {
          country: location.country,
          ...(location.state && { state: location.state || '' }),
        };
      }),
      ...(maxAge && {
        maxAge,
      }),
      ...(minAge && {
        minAge,
      }),
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    handleFilterSearch(criteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitLoading =
    isUploading ||
    loadingUpdateCampaign ||
    loadingCreateCampaignExampleFiles ||
    loadingStoreData;

  if (!record) return null;

  return (
    <div className={styles.root}>
      <Form
        name="EditCampaignForm"
        aria-label="Update Sponsored Appearance Campaign"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialFormValues}
      >
        <div className={styles.columnWrapper}>
          <div className={styles.switchWrapper}>
            <Text>Types of Stream & Podcast Campaign</Text>

            <Switch
              checked={isGuest}
              checkedChildren="Guest"
              unCheckedChildren="Product"
              onChange={handleChangeType}
            />
          </div>

          <div className={styles.switchWrapper}>
            <Text>Video Type</Text>

            <Switch
              checked={isLive}
              checkedChildren="Live"
              unCheckedChildren="Recorded"
              onChange={handleChangeVideoType}
            />
          </div>

          {isLive && (
            <div className={styles.switchWrapper}>
              <Text>AMPLIFY to socials</Text>
              <Checkbox
                value={isAmplify}
                onChange={handleChangeAmplify}
                checked={isAmplify}
              />
            </div>
          )}
        </div>
        <div className={styles.heading}>Campaign Criteria(filters)</div>
        <div className={styles.columnWrapper}>
          <div className={styles.rowWrapper}>
            <Form.Item name="userRole" label="Type of talent">
              <Select
                placeholder="Select athlete type"
                mode="multiple"
                allowClear
                options={[
                  {
                    value: UserRole.Athlete,
                    label: 'Athlete',
                  },
                  {
                    value: UserRole.ContentCreator,
                    label: 'Content Creator',
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="budget"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: (_, value) => {
                    if (value && value < 2500) {
                      return Promise.reject(
                        new Error('Minimum campaign budget is $2500 USD.')
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label={<Text>Full Campaign Budget</Text>}
            >
              <Input type="number" placeholder="Budget" aria-label="budget" />
            </Form.Item>
            <Form.Item
              name="flatFeePerStore"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Price per Talent</Text>}
            >
              <Input
                type="number"
                placeholder="Price per Talent"
                aria-label="Price per talent"
              />
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="sports"
              label="Sport:"
              rules={[
                {
                  required: true,
                  message: 'Please, select sport',
                },
              ]}
            >
              <Select
                id="sport"
                placeholder="Select sport type"
                mode="multiple"
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {allSports.map((item) => {
                  return (
                    <Option value={`${item.name}`} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="genders" label={<Text>Gender</Text>}>
              <Select
                id="genders"
                placeholder="Select gender"
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {GENDER_OPTIONS.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item name="minAge" label={<Text>Minimum Age</Text>}>
              <Input
                type="number"
                placeholder="Minimum Age"
                aria-label="Minimum Age"
              />
            </Form.Item>
            <Form.Item name="maxAge" label={<Text>Maximum Age</Text>}>
              <Input
                type="number"
                placeholder="Maximum Age"
                aria-label="Maximum Age"
              />
            </Form.Item>
          </div>

          <div className={styles.rowWrapper}>
            <Form.Item name="followingFrom" label="Social following min:">
              <Select
                id="followingFrom"
                placeholder="Select"
                allowClear
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {FOLLOWING_OPTIONS_MIN.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="followingTo" label="Social following max:">
              <Select
                id="followingTo"
                placeholder="Select"
                allowClear
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {FOLLOWING_OPTIONS_MAX.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.filterButtonWrapper}>
            <Button
              type="primary"
              name="filterButton"
              loading={loadingStoreData}
              onClick={handleFilterClick}
            >
              Refresh List
            </Button>
          </div>

          {showTalentList ? (
            <TalentList
              talents={talentsData || []}
              hasMore={hasMore}
              totalTalents={totalTalents}
              totalFollowers={totalFollowers}
            />
          ) : !loadingStoreData ? (
            <div className={styles.noItems}>
              No talents found with the set criteria
            </div>
          ) : null}

          <div className={styles.campaignHeading}>Campaign Details:</div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Campaign name</Text>}
            >
              <Input
                type="name"
                placeholder="Enter Campaign name"
                aria-label="Campaign name"
              />
            </Form.Item>

            <Form.Item
              name="dueDate"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: (_, value) => {
                    if (value && moment().add(2, 'weeks').isAfter(value)) {
                      return Promise.reject(
                        new Error(
                          'Application deadline must be at least 2 weeks in the future.'
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label={<Text>Application Deadline</Text>}
            >
              <DatePicker
                name="dueDate"
                format={dateTimeFormat}
                showTime
                disabledDate={disabledDate}
              />
            </Form.Item>
          </div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="usageRights"
              label={<Text>Usage Rights</Text>}
              valuePropName="checked"
              initialValue={usageRights}
            >
              <Checkbox
                value={usageRights}
                onChange={handleUsageRightsChange}
              />
            </Form.Item>

            <Form.Item
              name="usageRightsText"
              rules={[
                {
                  required: usageRights,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Usage rights text</Text>}
            >
              <TextArea
                rows={6}
                id="usageRights"
                placeholder="Usage rights text"
                aria-label="Usage rights text"
              />
            </Form.Item>
          </div>

          {isGuest ? (
            <>
              <div className={styles.heading}>Guest Details</div>

              <div className={styles.threeItemsRowWrapper}>
                <Form.Item
                  name="guestName"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                  label={<Text>Guest Name</Text>}
                >
                  <Input
                    type="guestName"
                    placeholder="Enter Guest name"
                    aria-label="Guest name"
                  />
                </Form.Item>

                <Form.Item
                  name="company"
                  label={<Text>Company (Optional)</Text>}
                >
                  <Input
                    type="company"
                    placeholder="Enter Company name"
                    aria-label="Company name"
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                    {
                      type: 'email',
                      message: 'Please input a valid email address',
                    },
                  ]}
                  label={<Text>Guest E-Mail</Text>}
                >
                  <Input
                    type="email"
                    placeholder="Enter Guest email"
                    aria-label="Guest email"
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="guestBio"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                  label={<Text>Bio</Text>}
                >
                  <TextArea
                    autoSize={{ minRows: 3 }}
                    name="guestBio"
                    placeholder="Please give the host some background on you and your product/service/company to decide if its a good fit for the show/stream."
                  />
                </Form.Item>
              </div>
            </>
          ) : (
            <>
              <div className={styles.heading}>Brand info</div>

              <div className={styles.rowWrapper}>
                <Form.Item
                  name="brandName"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                  label={<Text>{"Brand's name"}</Text>}
                >
                  <Input
                    type="brandName"
                    placeholder="Enter Brand's name"
                    aria-label="Brand name"
                  />
                </Form.Item>
                <Form.Item name="website" label={<Text>Website</Text>}>
                  <Input
                    type="website"
                    placeholder="Website"
                    aria-label="Website"
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="productBio"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                  label={<Text>Bio</Text>}
                >
                  <TextArea
                    autoSize={{ minRows: 3 }}
                    name="productBio"
                    placeholder="Please give the host some background on you and your product/service/company to decide if its a good fit for the show/stream."
                  />
                </Form.Item>
              </div>
            </>
          )}

          <div className={styles.heading}>Social Media Accounts</div>

          <div className={styles.threeItemsRowWrapper}>
            <Form.Item name="instagram" label={<Text>Instagram</Text>}>
              <Input
                type="instagram"
                placeholder="Enter Instagram"
                aria-label="Instagram"
              />
            </Form.Item>

            <Form.Item name="linkedin" label={<Text>LinkedIn</Text>}>
              <Input
                type="linkedin"
                placeholder="Enter LinkedIn"
                aria-label="LinkedIn"
              />
            </Form.Item>
            <Form.Item name="otherSocial" label={<Text>Other</Text>}>
              <Input
                type="otherSocial"
                placeholder="Enter Other"
                aria-label="Other"
              />
            </Form.Item>
          </div>

          {isGuest ? (
            <Form.Item
              name="instructions"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Instructions</Text>}
            >
              <TextArea
                autoSize={{ minRows: 3 }}
                name="instructions"
                placeholder="Enter instructions"
              />
            </Form.Item>
          ) : (
            <>
              <div className={styles.heading}>
                Product Info and Instructions
              </div>

              <div className={styles.rowWrapper}>
                <Form.Item
                  name="physicalProduct"
                  label={
                    <Text>Yes, I need to send them physical products</Text>
                  }
                  valuePropName="checked"
                  initialValue={isPhysicalProduct}
                >
                  <Checkbox
                    value={isPhysicalProduct}
                    onChange={handleIsPhysicalProductChange}
                  />
                </Form.Item>
                <Form.Item
                  name="physicalProductType"
                  rules={[
                    {
                      required: isPhysicalProduct,
                      message: 'This field is required',
                    },
                  ]}
                  label={<Text>Product Shipping Category</Text>}
                >
                  <Select
                    id="physicalProductType"
                    placeholder="Select"
                    disabled={!isPhysicalProduct}
                    filterOption={(inputValue, option) =>
                      option?.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                  >
                    {PRODUCT_TYPES.map((item) => {
                      return (
                        <Option value={`${item.value}`} key={item.label}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="instructions"
                  label={<Text>Campaign instructions</Text>}
                >
                  <TextArea
                    autoSize={{ minRows: 3 }}
                    name="instructions"
                    placeholder="Enter instructions"
                  />
                </Form.Item>
              </div>

              <div className={styles.examplesHeading}>Examples and Uploads</div>

              <UploadMultipleImages
                images={productImages}
                setImages={handleSetImages}
                isMainImage={null}
              />
            </>
          )}
        </div>
        <div className={styles.submitButtonWrapper}>
          <Button type="primary" htmlType="submit" loading={submitLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SponsoredAppearanceCampaignEditModal;
