// Types

import { GetCampaignsV2_getCampaigns_entities } from 'api/campaignV2/types/GetCampaignsV2';
import {
  CampaignSmPlatform,
  CampaignType,
  SocialMediaType,
} from '../api/graphql-global-types';
import {
  GetCampaignOffers_getCampaignOffers_entities,
  GetCampaignOffers_getCampaignOffers_entities_user_socialMedia,
} from 'api/campaignV2/types/GetCampaignOffers';

export const getSocialMedia = (
  offer: GetCampaignOffers_getCampaignOffers_entities
): GetCampaignOffers_getCampaignOffers_entities_user_socialMedia | null => {
  const socialMedia = offer?.user?.socialMedia;
  const campaign = offer.campaign;
  const campaignType = campaign?.type;
  const smPlatforms = campaign?.search?.smPlatforms;
  const isInstagram = smPlatforms?.[0] === CampaignSmPlatform.IG;

  if (!socialMedia) {
    return null;
  }

  if (
    campaignType === CampaignType.Social ||
    campaignType === CampaignType.SocialShare
  ) {
    return (
      socialMedia.find((item) =>
        isInstagram
          ? item.type === SocialMediaType.Instagram
          : item.type === SocialMediaType.Tiktok
      ) || null
    );
  } else {
    const sortedSocialMedia = [...socialMedia].sort(
      (a, b) => Number(b.followingCnt) - Number(a.followingCnt)
    );
    return sortedSocialMedia[0] || null;
  }
};

export const getSocialMediaType = (
  record: GetCampaignsV2_getCampaigns_entities
): SocialMediaType | null => {
  const smPlatforms = record?.search?.smPlatforms;

  const isInstagram = smPlatforms?.[0] === CampaignSmPlatform.IG;
  const isTiktok = smPlatforms?.[0] === CampaignSmPlatform.TT;

  return isInstagram
    ? SocialMediaType.Instagram
    : isTiktok
    ? SocialMediaType.Tiktok
    : null;
};
