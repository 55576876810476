import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Form,
  Input,
  Typography,
  Select,
  DatePicker,
  Switch,
  Button,
  Checkbox,
} from 'antd';
// Api
import { UPDATE_SOCIAL_SHARE_CAMPAIGN } from 'api/campaignV2/mutations';
import { GET_COUNTRY_LIST } from 'api/users/queries';
import { SEARCH_SOCIAL_SHARE_CAMPAIGN_STORES } from 'api/campaignV2/queries';
// Hooks
import { useGetSports } from 'hooks';
// Helpers
import {
  FOLLOWING_OPTIONS_MAX,
  FOLLOWING_OPTIONS_MIN,
  GENDER_OPTIONS,
  isUsOrCa,
} from 'helpers/campaignEditModal';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_SocialShareActivity,
  GetCampaignsV2_getCampaigns_entities_search,
} from 'api/campaignV2/types/GetCampaignsV2';
import {
  CampaignActivityType,
  CampaignLocationInput,
  CampaignSmPlatform,
  CampaignTaskType,
  SearchCampaignCriteriaInput,
  SearchSocialShareCriteriaInput,
  SocialShareActivityInput,
  SortDirection,
  StoreGender,
  UpdateSocialShareCampaignInput,
  UserRole,
} from 'api/graphql-global-types';
import { GetCountryList } from 'api/users/types/GetCountryList';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  SearchSocialShareCampaignStores,
  SearchSocialShareCampaignStoresVariables,
} from 'api/campaignV2/types/SearchSocialShareCampaignStores';
import {
  UpdateSocialShareCampaign,
  UpdateSocialShareCampaignVariables,
} from 'api/campaignV2/types/UpdateSocialShareCampaign';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import TalentList from '../../../CampaignDetails/CampaignDetails/components/TalentList/TalentList';
// Style
import styles from './SocialShareCampaignEditModal.module.scss';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

type FormValues = {
  userRole: UserRole[];
  budget: number;
  direction?: SortDirection | null;
  flatFeePerStore: number;
  genders?: StoreGender[] | null;
  locations?: CampaignLocationInput[] | null;
  maxAge?: number | null;
  minAge?: number | null;
  smPlatforms: CampaignSmPlatform[];
  followingFrom: number | null;
  followingTo: number | null;
  sports: string[];
  brandName: string;
  website: string;
  description: string;
  campaignName: string;
  campaignInstructions: string;
  deliveryDate: any;
  shareUrl: string;
  usageRightsText: string | null;
};

type SocialShareCampaignEditModalProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onFinish: () => void;
};

const SocialShareCampaignEditModal = ({
  record,
  onFinish,
}: SocialShareCampaignEditModalProps): JSX.Element | null => {
  const [form] = Form.useForm();

  const activity = record?.activities?.find(
    (item) => item.__typename === 'SocialShareActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SocialShareActivity;

  const [selectedTasks, setSelectedTasks] = useState<CampaignTaskType[]>(
    activity?.tasks.map((item) => {
      return item.type;
    })
  );

  const [usageRights, setUsageRights] = useState<boolean>(
    record?.usageRights || false
  );

  const [isInstagram, setIsInstagram] = useState<boolean>(
    !selectedTasks.some((item) => {
      return item === CampaignTaskType.TT_VIDEO;
    })
  );

  const [selectedLocations, setSelectedLocations] = useState<
    CampaignLocationInput[]
  >([]);

  const filters = record?.search as GetCampaignsV2_getCampaigns_entities_search;
  const locations = filters?.locations;

  const { data: sportsData } = useGetSports({
    variables: {
      storeRoles: [UserRole.Athlete, UserRole.ContentCreator],
    },
  });

  const { data: countriesList } = useQuery<GetCountryList>(GET_COUNTRY_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    searchSocialShareStores,
    { data: storeData, loading: loadingStoreData },
  ] = useLazyQuery<
    SearchSocialShareCampaignStores,
    SearchSocialShareCampaignStoresVariables
  >(SEARCH_SOCIAL_SHARE_CAMPAIGN_STORES);

  const [updateSocialShareCampaign, { loading: loadingUpdateCampaign }] =
    useMutation<UpdateSocialShareCampaign, UpdateSocialShareCampaignVariables>(
      UPDATE_SOCIAL_SHARE_CAMPAIGN
    );

  const sports = sportsData?.sports || [];

  useEffect(() => {
    const initialLocations = locations.map((location) => {
      return {
        country: location.country,
        state: location.state || '',
      };
    });

    setSelectedLocations(initialLocations);
  }, [locations]);

  const initialFormValues: FormValues = {
    userRole: filters?.roles || [],
    budget: filters?.budget || 0,
    flatFeePerStore: record?.flatFeePerStore || 0,
    genders: filters?.genders || [],
    maxAge: filters?.athleteMaxAge || null,
    minAge: filters?.athleteMinAge || null,
    smPlatforms: filters?.smPlatforms || [],
    followingFrom:
      FOLLOWING_OPTIONS_MIN.find((f) => f.value === filters.minFollowers)
        ?.value || null,
    followingTo:
      FOLLOWING_OPTIONS_MAX.find((f) => f.value === filters.maxFollowers)
        ?.value || null,
    sports: filters?.sports || [],
    brandName: activity?.brandName || '',
    website: activity?.website || '',
    description: activity?.socialShareDescription || '',
    campaignName: record?.name || '',
    campaignInstructions: activity?.instructions || '',
    deliveryDate: record?.dueDate ? moment(record?.dueDate) : null,
    shareUrl: activity?.shareUrl || '',
    usageRightsText: record?.usageRightsText || null,
  };

  const handleSubmit = async () => {
    const {
      userRole,
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      followingFrom,
      followingTo,
      sports,
      brandName,
      website,
      description,
      deliveryDate,
      campaignInstructions,
      campaignName,
      shareUrl,
      usageRightsText,
    } = form.getFieldsValue();

    const criteria: SearchSocialShareCriteriaInput = {
      roles: userRole?.length
        ? userRole
        : [UserRole.Athlete, UserRole.ContentCreator],
      budget: Number(budget),
      flatFeePerStore: Number(flatFeePerStore),
      genders: genders || null,
      locations: selectedLocations.map((location) => {
        return {
          country: location.country ?? '',
          ...(location.state && { state: location.state }),
        };
      }),
      ...(maxAge && {
        maxAge: Number(maxAge),
      }),
      ...(minAge && {
        minAge: Number(minAge),
      }),
      smPlatforms: isInstagram
        ? [CampaignSmPlatform.IG]
        : [CampaignSmPlatform.TT],
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    const activities: SocialShareActivityInput[] = [
      {
        socialShare: {
          brandName: brandName,
          instructions: campaignInstructions,
          ...(website && { website }),
          shareUrl: shareUrl,
          description: description,
        },
        type: CampaignActivityType.SocialShare,
      },
    ];

    const updateCampaignInputVariables: UpdateSocialShareCampaignInput = {
      id: record?.id || '',
      criteria,
      activities,
      name: campaignName,
      ownerId: record?.agency.id,
      dueDate: deliveryDate,
      usageRights,
      usageRightsText,
    };

    try {
      const { data } = await updateSocialShareCampaign({
        variables: {
          input: updateCampaignInputVariables,
        },
      });
      if (data?.updateSocialShareCampaign) {
        successNotification('The campaign was successfully updated');
        onFinish();
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign update error:', { error });
    }
  };

  const handleChangeSocialNetworkType = () => {
    if (isInstagram) {
      setIsInstagram(false);
      setSelectedTasks([CampaignTaskType.TT_VIDEO]);
      form.setFieldsValue({ mediaType: [] });
    } else {
      setIsInstagram(true);
      setSelectedTasks([]);
    }
  };

  const getStateList = (selectedCountry: string) => {
    const countryData = countriesList?.getCountryList.find((country) => {
      return (
        country.code === selectedCountry || country.name === selectedCountry
      );
    });
    return countryData?.states;
  };

  const handleCountryChange = (country: string, index: number) => {
    const copyLocations = [...selectedLocations];

    if (isUsOrCa(country)) {
      copyLocations[index] = { country, state: '' };
    } else {
      copyLocations[index] = { country };
    }
    setSelectedLocations(copyLocations);
  };

  const handleStateChange = (state: string, index: number) => {
    const copyLocations = [...selectedLocations];

    const country = copyLocations[index].country;

    copyLocations[index] = { country, state };

    setSelectedLocations(copyLocations);
  };

  const handleDeleteLocation = (index: number) => {
    setSelectedLocations(
      selectedLocations.filter((_, counter) => counter !== index)
    );
  };

  const handleAddLocation = () => {
    setSelectedLocations([...selectedLocations, { country: '' }]);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // allow days 2 weeks from today
    return current && current < moment().add('2', 'weeks');
  };

  const handleFilterSearch = async (criteria: SearchCampaignCriteriaInput) => {
    try {
      searchSocialShareStores({
        variables: {
          input: {
            limit: 4,
            offset: 0,
            criteria,
          },
        },
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign filter error:', { error });
    }
  };

  const handleFilterClick = async () => {
    const {
      userRole,
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      followingFrom,
      followingTo,
      sports,
    } = form.getFieldsValue();

    const criteria: SearchCampaignCriteriaInput = {
      roles: userRole?.length
        ? userRole
        : [UserRole.Athlete, UserRole.ContentCreator],
      budget: Number(budget),
      flatFeePerStore: Number(flatFeePerStore),
      genders: genders || null,
      locations: selectedLocations.map((location) => {
        return {
          country: location.country ?? '',
          ...(location.state && { state: location.state }),
        };
      }),
      ...(maxAge && {
        maxAge: Number(maxAge),
      }),
      ...(minAge && {
        minAge: Number(minAge),
      }),
      smPlatforms: [
        isInstagram ? CampaignSmPlatform.IG : CampaignSmPlatform.TT,
      ],
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    handleFilterSearch(criteria);
  };

  const handleUsageRightsChange = () => {
    if (usageRights) {
      form.setFieldsValue({
        usageRights: null,
      });
    }

    setUsageRights(!usageRights);
  };

  const talentsData = storeData?.searchSocialShareCampaignStores?.entities;

  const hasMore =
    (talentsData?.length ?? 0) <
    (storeData?.searchSocialShareCampaignStores.total ?? 0);

  const showTalentList =
    !!talentsData?.length && talentsData?.length > 0 && !loadingStoreData;

  const totalFollowers = Number(
    storeData?.searchSocialShareCampaignStores.summary.followers
  );
  const totalTalents = Number(storeData?.searchSocialShareCampaignStores.total);

  useEffect(() => {
    // Used to call the filters API and display the initial talents
    const {
      userRole,
      budget,
      flatFeePerStore,
      genders,
      maxAge,
      minAge,
      smPlatforms,
      followingFrom,
      followingTo,
      sports,
    } = initialFormValues;

    const criteria: SearchCampaignCriteriaInput = {
      roles: userRole,
      budget,
      flatFeePerStore,
      genders,
      locations: filters?.locations.map((location) => {
        return {
          country: location.country,
          ...(location.state && { state: location.state || '' }),
        };
      }),
      ...(maxAge && {
        maxAge,
      }),
      ...(minAge && {
        minAge,
      }),
      smPlatforms,
      ...(followingFrom && {
        socialFollowersRange: {
          from: Number(followingFrom),
        },
      }),
      ...(followingTo && {
        socialFollowersRange: {
          to: Number(followingTo),
        },
      }),
      ...(followingTo &&
        followingFrom && {
          socialFollowersRange: {
            from: Number(followingFrom),
            to: Number(followingTo),
          },
        }),
      sports,
    };

    handleFilterSearch(criteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitLoading = loadingUpdateCampaign || loadingStoreData;

  if (!record) return null;

  return (
    <div className={styles.root}>
      <Form
        name="EditCampaignForm"
        aria-label="Update Social Campaign"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialFormValues}
      >
        <div className={styles.heading}>Campaign Criteria(filters):</div>
        <div className={styles.columnWrapper}>
          <div className={styles.switchWrapper}>
            <Text>Choose Platform (Instagram/TikTok):</Text>

            <Switch
              checked={isInstagram}
              checkedChildren="Instagram"
              unCheckedChildren="TikTok"
              onChange={handleChangeSocialNetworkType}
            />
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item name="userRole" label="Type of talent">
              <Select
                placeholder="Select athlete type"
                mode="multiple"
                allowClear
                options={[
                  {
                    value: UserRole.Athlete,
                    label: 'Athlete',
                  },
                  {
                    value: UserRole.ContentCreator,
                    label: 'Content Creator',
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="budget"
              rules={[
                {
                  required: true,
                  message: 'Budget is required',
                },
                {
                  validator: (_, value) => {
                    if (value && value < 2500) {
                      return Promise.reject(
                        new Error('Minimum campaign budget is $2500 USD.')
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label={<Text>Budget</Text>}
            >
              <Input type="number" placeholder="Budget" aria-label="budget" />
            </Form.Item>
            <Form.Item
              name="flatFeePerStore"
              rules={[
                {
                  required: true,
                  message: 'Price per talent is required',
                },
              ]}
              label={<Text>Price per Talent</Text>}
            >
              <Input
                type="number"
                placeholder="Price per talent"
                aria-label="Price per talent"
              />
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="sports"
              label="Sport:"
              rules={[
                {
                  required: true,
                  message: 'Sport is required',
                },
              ]}
            >
              <Select
                id="sport"
                placeholder="Select sport type"
                mode="multiple"
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {sports.map((item) => {
                  return (
                    <Option value={`${item.name}`} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="genders" label={<Text>Gender</Text>}>
              <Select
                id="genders"
                placeholder="Select gender"
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {GENDER_OPTIONS.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.rowWrapper}>
            <Form.Item name="minAge" label={<Text>Minimum Age</Text>}>
              <Input
                type="number"
                placeholder="Minimum Age"
                aria-label="Minimum Age"
              />
            </Form.Item>
            <Form.Item name="maxAge" label={<Text>Maximum Age</Text>}>
              <Input
                type="number"
                placeholder="Maximum Age"
                aria-label="Maximum Age"
              />
            </Form.Item>
          </div>

          <div className={styles.rowWrapper}>
            <Form.Item name="followingFrom" label="Social following min:">
              <Select
                id="followingFrom"
                placeholder="Select"
                allowClear
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {FOLLOWING_OPTIONS_MIN.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="followingTo" label="Social following max:">
              <Select
                id="followingTo"
                placeholder="Select"
                allowClear
                filterOption={(inputValue, option) =>
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {FOLLOWING_OPTIONS_MAX.map((item) => {
                  return (
                    <Option value={`${item.value}`} key={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.locationsWrapper}>
            <div className={styles.locationsHeading}>Locations:</div>

            {selectedLocations.map((location, index) => (
              <div key={index} className={styles.location}>
                <Form.Item
                  key={index}
                  rules={[
                    {
                      required: true,
                      message: 'Country is required',
                    },
                  ]}
                  className={styles.location}
                >
                  <Select
                    value={location.country || null}
                    onChange={(e) => handleCountryChange(e, index)}
                    placeholder="Select Country"
                  >
                    {countriesList?.getCountryList.map((country) => (
                      <Option key={country.code} value={country.code}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {isUsOrCa(location.country) ? (
                  <Select
                    value={location.state || null}
                    onChange={(e) => handleStateChange(e, index)}
                    placeholder="Select State"
                  >
                    {getStateList(location.country)?.map((state) => {
                      return (
                        <Option key={state.code} value={state.code}>
                          {state.name}
                        </Option>
                      );
                    })}
                  </Select>
                ) : null}
                <Button
                  className={styles.locationButton}
                  type="primary"
                  danger
                  onClick={() => handleDeleteLocation(index)}
                >
                  Delete Location
                </Button>
              </div>
            ))}
            <Button
              className={styles.locationButton}
              type="primary"
              onClick={handleAddLocation}
            >
              Add Another Location
            </Button>
          </div>

          <div className={styles.filterButtonWrapper}>
            <Button
              type="primary"
              name="filterButton"
              loading={loadingStoreData}
              onClick={handleFilterClick}
            >
              Refresh List
            </Button>
          </div>

          {showTalentList ? (
            <TalentList
              talents={talentsData || []}
              hasMore={hasMore}
              totalTalents={totalTalents}
              totalFollowers={totalFollowers}
            />
          ) : !loadingStoreData ? (
            <div className={styles.noItems}>
              No talents found with the set criteria
            </div>
          ) : null}

          <div className={styles.campaignHeading}>Campaign Details:</div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="campaignName"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Campaign name</Text>}
            >
              <Input
                type="campaignName"
                placeholder="Enter Campaign name"
                aria-label="Campaign name"
              />
            </Form.Item>

            <Form.Item
              name="deliveryDate"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: (_, value) => {
                    if (value && moment().add(2, 'weeks').isAfter(value)) {
                      return Promise.reject(
                        new Error(
                          'Application deadline must be at least 2 weeks in the future.'
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label={<Text>Application Deadline</Text>}
            >
              <DatePicker
                name="deliveryDate"
                format={onlyDateTimeFormat}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="usageRights"
              label={<Text>Usage Rights</Text>}
              valuePropName="checked"
              initialValue={usageRights}
            >
              <Checkbox
                value={usageRights}
                onChange={handleUsageRightsChange}
              />
            </Form.Item>

            <Form.Item
              name="usageRightsText"
              rules={[
                {
                  required: usageRights,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Usage rights text</Text>}
            >
              <TextArea
                rows={6}
                id="usageRights"
                placeholder="Usage rights text"
                aria-label="Usage rights text"
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name="campaignInstructions"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Campaign instructions</Text>}
            >
              <TextArea
                autoSize={{ minRows: 3 }}
                name="campaignInstructions"
                placeholder="Enter detailed instructions. Let talent know where you want them to share your post to and when. Include any links or copy you want them including on the share."
              />
            </Form.Item>
          </div>

          <div className={styles.heading}>Company Details:</div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="brandName"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Name</Text>}
            >
              <Input
                type="text"
                placeholder="Enter Brand or Company Name"
                aria-label="Enter Brand or Company Name"
              />
            </Form.Item>
            <Form.Item name="website" label={<Text>Company Website</Text>}>
              <Input
                type="text"
                placeholder="https://example.com"
                aria-label="https://example.com"
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Description</Text>}
            >
              <TextArea
                autoSize={{ minRows: 3 }}
                name="description"
                placeholder="Enter Company/Brand product/service description"
              />
            </Form.Item>
          </div>

          <div className={styles.heading}>Post Details:</div>

          <div className={styles.rowWrapper}>
            <Form.Item
              name="shareUrl"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Post Link</Text>}
            >
              <Input
                type="text"
                placeholder="https://example.link"
                aria-label="https://example.link"
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.submitButtonWrapper}>
          <Button type="primary" htmlType="submit" loading={submitLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SocialShareCampaignEditModal;
