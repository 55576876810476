import React from 'react';
// Helpers
import { formatAudienceSize } from 'helpers/campaignEditModal';
import { getSocialMedia } from 'helpers/following';
// Types
import { GetCampaignOffers_getCampaignOffers_entities } from 'api/campaignV2/types/GetCampaignOffers';
import { SocialMediaType } from 'api/graphql-global-types';
// Styles
import styles from './Followers.module.scss';

type FollowersColumnProps = {
  row: GetCampaignOffers_getCampaignOffers_entities;
};

const Followers = ({ row }: FollowersColumnProps): JSX.Element => {
  const followers = getSocialMedia(row);

  const isInstagram = followers?.type === SocialMediaType.Instagram;
  const isTiktok = followers?.type === SocialMediaType.Tiktok;

  return (
    <div className={styles.root}>
      <div className={styles.audience}>
        <p>
          {formatAudienceSize(followers?.followingCnt?.toString() ?? '', 1)}
        </p>

        <span className={styles.platform}>
          {isInstagram && 'Instagram'}
          {isTiktok && 'TikTok'}
        </span>
      </div>

      <div className={styles.linkWrapper}>
        <a
          href={followers?.url || ''}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          View Profile
        </a>
      </div>
    </div>
  );
};

export default Followers;
