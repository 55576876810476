import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Modal, Space } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// Helpers
import getEnvVariables from 'api/env';
// Routes
import { CAMPAIGN_DETAILS_GEN } from 'constants/routes';
// Api
import {
  APPROVE_CAMPAIGN,
  DECLINE_CAMPAIGN,
  COMPLETE_CAMPAIGN,
} from 'api/campaignV2/mutations';
// Types
import {
  DeclineCampaign,
  DeclineCampaignVariables,
} from 'api/campaignV2/types/DeclineCampaign';
import {
  ApproveCampaign,
  ApproveCampaignVariables,
} from 'api/campaignV2/types/ApproveCampaign';
import {
  CompleteCampaign,
  CompleteCampaignVariables,
} from 'api/campaignV2/types/CompleteCampaign';
import { GetCampaignsV2_getCampaigns_entities } from 'api/campaignV2/types/GetCampaignsV2';
import { CampaignStatus, CampaignType } from 'api/graphql-global-types';
import SocialShareCampaignEditModal from '../SocialShareCampaignEditModal/SocialShareCampaignEditModal';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import SocialCampaignsEditModal from '../SocialCampaignsEditModal/SocialCampaignsEditModal';
import SponsoredAppearanceCampaignEditModal from '../SponsoredAppearanceCampaignEditModal/SponsoredAppearanceCampaignEditModal';
import BookMeCampaignsEditModal from '../BookMeCampaignsEditModal/BookMeCampaignsEditModal';
import EventMarketingDetailsModal from '../EventMarketingDetailsModal/EventMarketingDetailsModal';
import EventMarketingEditModal from '../EventMarketingEditModal/EventMarketingEditModal';
import ChangeOwnershipModal from '../ChangeOwnershipModal/ChangeOwnershipModal';
// Styles
import styles from './CampaignsActions.module.scss';

const { confirm } = Modal;

type CampaignsActionsProps = {
  data: GetCampaignsV2_getCampaigns_entities;
  refetchCampaigns: () => void;
};
const CampaignsActions = ({
  data,
  refetchCampaigns,
}: CampaignsActionsProps): JSX.Element => {
  const history = useHistory();

  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showOwnershipModal, setShowOwnershipModal] = useState<boolean>(false);

  const [approveCampaign, { loading: loadingApprove }] = useMutation<
    ApproveCampaign,
    ApproveCampaignVariables
  >(APPROVE_CAMPAIGN, {
    onCompleted: () => {
      refetchCampaigns();
    },
  });

  const [declineCampaign, { loading: loadingDecline }] = useMutation<
    DeclineCampaign,
    DeclineCampaignVariables
  >(DECLINE_CAMPAIGN, {
    onCompleted: () => {
      refetchCampaigns();
    },
  });

  const [completeCampaign, { loading: loadingComplete }] = useMutation<
    CompleteCampaign,
    CompleteCampaignVariables
  >(COMPLETE_CAMPAIGN);

  const handleOpenCampaignDetails = () => {
    history.push(`${CAMPAIGN_DETAILS_GEN}/${data.id}`);
  };

  const handleOpenDetailsModal = () => {
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const handleOpenEditCampaignModal = () => {
    setShowEditModal(true);
  };

  const handleCloseEditCampaignModal = () => {
    setShowEditModal(false);
  };

  const handleOpenOwnershipModal = () => {
    setShowOwnershipModal(true);
  };

  const handleCloseOwnershipModal = () => {
    setShowOwnershipModal(false);
  };

  const handleUpdate = () => {
    refetchCampaigns();
    handleCloseEditCampaignModal();
  };

  const handleUpdateEventMarketing = () => {
    handleCloseEditCampaignModal();
  };

  const handleApproveCampaign = async () => {
    try {
      await approveCampaign({
        variables: {
          input: { id: data.id },
        },
      });
      successNotification('Campaign is approved');
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.log('approve campaign error:', { error });
    }
  };

  const handleDeclineCampaign = () => {
    confirm({
      title: 'Are you sure you want to decline this campaign?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await declineCampaign({
            variables: {
              input: { id: data.id },
            },
          });
          successNotification('Campaign is declined');
        } catch (error) {
          errorNotification((error as Error)?.message);
          console.log('decline campaign error:', { error });
        }
      },
    });
  };

  const handleCompleteCampaign = async () => {
    confirm({
      title: 'Are you sure you want to complete this campaign?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await completeCampaign({
            variables: {
              input: { id: data.id },
            },
          });
          successNotification('Campaign is completed');
        } catch (error) {
          errorNotification((error as Error)?.message);
          console.log('complete campaign error:', { error });
        }
      },
    });
  };

  const isLoading = loadingApprove || loadingDecline || loadingComplete;

  // if campaign is on review or only had details filled, we can approve, edit and reject
  const showExtraActions =
    data.status === CampaignStatus.ON_REVIEW ||
    data.status === CampaignStatus.DETAIL_FILLED;

  const showSocialEdit = data.type === CampaignType.Social;
  const showSocialShareEdit = data.type === CampaignType.SocialShare;
  const showBookMeEdit = data.type === CampaignType.BookMe;
  const showSponsoredEdit = data.type === CampaignType.SponsoredAppearance;
  const isEventMarketing = data.type === CampaignType.EventMarketing;
  // change ownership is requested to work on social, book me and sponsored campaigns that are in draft status (details_filled)
  const showChangeOwnership =
    data.status === CampaignStatus.DETAIL_FILLED &&
    data.type !== CampaignType.EventMarketing;
  const isCompleted = data?.status === CampaignStatus.COMPLETED;

  const getMenu = () => {
    return (
      <Menu>
        <Menu.Item key="details">
          <Space direction="vertical" size="middle" align="center">
            {showExtraActions && (
              <>
                <Button
                  onClick={handleApproveCampaign}
                  type="primary"
                  loading={isLoading}
                >
                  Approve
                </Button>
                <Button
                  onClick={handleDeclineCampaign}
                  type="primary"
                  danger
                  loading={isLoading}
                >
                  Decline
                </Button>
              </>
            )}

            {!isCompleted && (
              <Button
                onClick={handleCompleteCampaign}
                type="primary"
                loading={isLoading}
              >
                Complete Campaign
              </Button>
            )}

            {showExtraActions && (
              <Button
                onClick={handleOpenEditCampaignModal}
                type="primary"
                loading={isLoading}
              >
                Edit
              </Button>
            )}
            {isEventMarketing ? (
              <Button onClick={handleOpenDetailsModal} type="primary">
                Details
              </Button>
            ) : (
              <Button onClick={handleOpenCampaignDetails} type="primary">
                Details
              </Button>
            )}
            {showChangeOwnership && (
              <Button onClick={handleOpenOwnershipModal} type="primary">
                Change Ownership
              </Button>
            )}
            <a
              href={`${getEnvVariables.CAMPAIGN_APP_URL}/${data.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.viewCampaign}
            >
              View campaign
            </a>
          </Space>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement="bottomCenter"
        overlay={getMenu()}
      >
        <Button
          aria-label="show the available actions"
          icon={<EllipsisOutlined />}
        />
      </Dropdown>

      <Modal
        title="Campaign details"
        visible={showDetailsModal}
        footer={null}
        onCancel={handleCloseDetailsModal}
        width="90vw"
      >
        <EventMarketingDetailsModal record={data} />
      </Modal>

      <Modal
        title="Edit Campaign"
        visible={showEditModal}
        footer={null}
        onCancel={handleCloseEditCampaignModal}
        width="90vw"
      >
        {showSocialEdit && (
          <SocialCampaignsEditModal record={data} onFinish={handleUpdate} />
        )}

        {showSocialShareEdit && (
          <SocialShareCampaignEditModal record={data} onFinish={handleUpdate} />
        )}

        {showBookMeEdit && (
          <BookMeCampaignsEditModal record={data} onFinish={handleUpdate} />
        )}

        {showSponsoredEdit && (
          <SponsoredAppearanceCampaignEditModal
            record={data}
            onFinish={handleUpdate}
          />
        )}

        {isEventMarketing && (
          <EventMarketingEditModal
            record={data}
            onFinish={handleUpdateEventMarketing}
          />
        )}
      </Modal>

      <Modal
        title="Change Campaign Owner"
        visible={showOwnershipModal}
        footer={null}
        onCancel={handleCloseOwnershipModal}
        width={970}
      >
        <ChangeOwnershipModal
          record={data}
          onFinish={handleCloseOwnershipModal}
        />
      </Modal>
    </>
  );
};

export default CampaignsActions;
