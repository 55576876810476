import React from 'react';
import { Form, Button, Input, Typography } from 'antd';
import cn from 'classnames';
// Types
import { errorNotification, successNotification } from 'ui/Notification';
import { StoreValue } from 'antd/lib/form/interface';
// Helpers
import { SocialShareData } from 'helpers/deliverables';
// Components
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';
// Styles
import styles from './SocialShareDeliverablesInput.module.scss';

type SocialShareDeliverablesInputProps = {
  label: string;
  isCompletedOrPublished: boolean;
  link: string;
  images: UploadImage[];
  placeholder?: string;
  setState: (state: SocialShareData) => void;
};

const { Text } = Typography;

const SocialShareDeliverablesInput = ({
  label,
  isCompletedOrPublished,
  link,
  images,
  placeholder,
  setState,
}: SocialShareDeliverablesInputProps): JSX.Element => {
  const normFile = (e: StoreValue) => {
    if (e && Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSetImages = (incomingData: UploadImage[]) => {
    setState({ link, images: [incomingData[0]] });
  };

  const handleChange = (value: string) => {
    setState({ link: value, images });
  };

  const handleCopyToClipboard = (link: string) => {
    try {
      navigator.clipboard.writeText(link);
      successNotification('Link copied to clipboard');
    } catch (e) {
      errorNotification('Failed to copy link to clipboard');
    }
  };

  // show images section if this is completed and image was added or if this is creation
  const showImages: boolean =
    (isCompletedOrPublished && !!images?.length) || !isCompletedOrPublished;

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.inputWrapper, {
          [styles.linkWrapper]: isCompletedOrPublished,
        })}
      >
        <Text className={styles.label}>{'Link to ' + label + ' share:'}</Text>
        <div
          className={cn(styles.inputContainer, {
            [styles.linkContainer]: isCompletedOrPublished,
          })}
          key={'post_link'}
        >
          {isCompletedOrPublished ? (
            <a
              className={styles.link}
              key={link}
              href={link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {link}
            </a>
          ) : (
            <Input
              placeholder={placeholder}
              value={link}
              onChange={(event) => {
                handleChange(event.target.value);
              }}
            />
          )}

          {isCompletedOrPublished && (
            <Button
              className={styles.addButton}
              type="primary"
              onClick={() => handleCopyToClipboard(link)}
            >
              Copy
            </Button>
          )}
        </div>
      </div>

      {showImages && (
        <div className={styles.imageWrapper}>
          <Text className={styles.label}>
            {'Screenshot of ' + label + ' share:'}
          </Text>

          <Form.Item
            className={styles.imageContainer}
            valuePropName="image"
            getValueFromEvent={normFile}
          >
            {isCompletedOrPublished ? (
              <div className={styles.submittedImages}>
                <a
                  className={styles.link}
                  key={images?.[0].data_key}
                  href={images?.[0].data_url || ''}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {images?.[0].data_key}
                </a>
              </div>
            ) : (
              <UploadMultipleImages
                buttonClassName={styles.uploadButtons}
                images={images}
                setImages={handleSetImages}
                isMainImage={null}
                isMultiple={false}
              />
            )}
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default SocialShareDeliverablesInput;
